<template>
    <v-container>
        <v-card>
            <v-card-title>{{t.medicamento}}</v-card-title>
            <v-card-text>
                <!--span class="subtitle-2">{{t.medicamento}}</span-->
                <v-form ref="form" v-model="valid" :lazy-validation="false">
                    <v-text-field ref="dosis" v-model="dosificacion" label="Dosis*" required :rules="drules"></v-text-field>
                </v-form>
            </v-card-text>
            <v-card-actions>

                <v-spacer></v-spacer>
                <v-btn  @click="$emit('cancel')" >Cancelar</v-btn>
                <v-btn color="success" @click="actualizarDosis" :disabled="!valid" >Actualizar</v-btn>
            </v-card-actions>

        </v-card>
    </v-container>
</template>
<script>
import CitaService from "@/services/cita.service.js";

export default {
    name: 'EdicionDosisMedicamento',
    data: function(){
        return {
            drules: [
                        v => (!!v&&(v.length>10||/^([0-1]-[0-1]-[0-1]){1}$/.test(v)&&(v.trim()!='0-0-0')))||'Completar dosis',
                        v => (!!v && v.length < 128)||'Máximo 128 caracteres'
                    ],
            valid:false,
            dosificacion:null
        };
    },
    props: {
            c: { required: true }, //cita--
            t: {requiered:true}   //tratamiento-medicamento
    },
    computed:{
    },
    watch:{

    },
    methods:{
        actualizarDosis:function(){
            const params= {id:this.t.tid, c:this.c, d:this.dosificacion};

            CitaService.updateDosis(params).then((data)=>{
                if(data > 0){
                    this.t.dosificacion = this.dosificacion;
                    this.$emit('updated', this.t);
                }
                else{
                    this.$root.showMessage('No se pudo actualizar la dosis', 'error');
                }
            });
        }
    },
    beforeMount(){

        this.dosificacion = this.t.dosificacion;
    }
}
</script>